@use "breakpoints.scss";

.metaBar {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--brandLight5);

    @include breakpoints.mobile {
        width: 100%;
    }


    .metaBarContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "firstChild firstChild"
            "splitChild1 splitChild2"
            "splitChild3 splitChild4";
        padding: 0;
    }

    .metaBarItem:first-child {
        grid-area: firstChild;
        border-top: none;
    }

    // .metaBarItem:last-child {
    //     grid-area: lastChild;
    // }

    .metaBarItem:nth-child(2) {
        grid-area: splitChild1;
        border-right: 1px solid var(--brandLight5);
    }

    .metaBarItem:nth-child(3) {
        grid-area: splitChild2;
        padding-right: 8px;
        //border-left: 1px solid transparent;

    }

    .metaBarItem:nth-child(3) {
        grid-area: splitChild3;
        border-right: 1px solid var(--brandLight5);
    }

    .metaBarItem:nth-child(4) {
        grid-area: splitChild4;
        padding-left: 6px;
        //border-right: 2px solid transparent;

    }

    .metaBarItem {
        padding: 12px;
        display: flex;
        justify-content: space-around;
        border-top: 1px solid var(--brandLight5);

    }

}

/* Applies to medium screens (+768px) */
@media screen and (breakpoints.$mdUp) {
    .metaBar {


        .metaBarContainer {

            grid-template-columns: 1fr 1fr 1fr;
            grid-template-areas:
                "firstChild firstChild splitChild1"
                "splitChild2 splitChild3 splitChild4";

            padding: 0;
        }

        .metaBarItem:first-child {
            grid-area: firstChild;
            border-top: none;
        }


        .metaBarItem:nth-child(2) {
            grid-area: splitChild1;
            border-left: 1px solid var(--brandLight5);
        }

        .metaBarItem:nth-child(3) {
            grid-area: splitChild2;
            padding-right: 8px;

        }

        .metaBarItem:nth-child(3) {
            grid-area: splitChild3;
            border-left: 1px solid var(--brandLight5);
            border-right: 1px solid var(--brandLight5);
        }

        .metaBarItem:nth-child(4) {
            grid-area: splitChild4;
            padding-left: 18px;
        }


    }
}

/* Applies to large screens (+992px) */
@media screen and (breakpoints.$lgUp) {
    .metaBar {
        display: flex;
        justify-content: space-between;
        border: 1px solid var(--brandLight5);
        border-radius: 12px;
        flex-grow: 1;


        .metaBarContainer {
            display: grid;
            grid-template-columns: auto auto auto auto auto auto auto;
            grid-template-areas: initial !important;
        }

        .metaBarItem {
            padding: 7px 14px;
            border-top: none;
            border-left: none !important;
            border-right: 1px solid var(--brandLight5);
            grid-area: initial !important;

        }

        // .metaBarItem:first-child {
        //     padding-left: calc(1.5rem / 2);
        // }

        .metaBarItem:last-child {
            border-right: none;

        }
    }
}