@use "various.scss";
@use "breakpoints.scss";

.box {
    padding: various.$padding-base-2x;
    background: var(--gray50);
    border-radius: 12px;
    border: 1px solid var(--brandLight4);

    &.noContent {
        .boxTitle {
            border-bottom: none;

        }
    }
}

.boxTitle {

    font-weight: 500;
    margin-bottom: various.$padding-base-2x;
    border-bottom: 1px solid var(--brandLight4);
    margin: -12px -12px 0 -12px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 6px 0;


    .boxTools {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        margin: 0 12px 0 12px;
    }

    h3 {

        padding: 7px 12px 7px 12px;
    }
}

.compact {
    padding: various.$padding-base-2x;
}

.brandBox {
    background: var(--brandLight10);
    border: 1px solid var(--brandLight5);

    .boxTitle {
        border-bottom: 1px solid var(--brandLight5);
    }
}

.transparentBox,
.listBox {
    background: var(--mainBg);
    border: 1px solid var(--brandLight5);

    .boxTitle {
        border-bottom: 1px solid var(--brandLight5);
    }
}

.listBoxStackTop {
    border-radius: 12px 12px 0 0 !important;
    border-bottom: none !important;
}

.listBoxStackBottom {
    border-radius: 0 0 12px 12px !important;
    background: var(--mainBg);


    .boxTitle {
        background: var(--brandLight10);
    }
}

.noDataBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    gap: various.$padding-base-3x;
    border-style: dashed;
    margin-top: various.$padding-base-3x;


    div {
        font-weight: 500;
    }

    svg {
        font-size: 28px;
    }
}

/* Applies to medium screens (+768px) */
@media screen and (breakpoints.$mdUp) {
    .box {
        padding: various.$padding-base-4x;

        &.noContent {
            .boxTitle {
                border-bottom: none;
                margin: -24px;
            }
        }
    }

    .compact {
        padding: various.$padding-base-2x;
    }

    .menu {
        padding: 0;
    }

    .boxTitle {
        flex-direction: row;

        margin-left: -24px;
        margin-right: -24px;
        margin-top: -24px;
        padding: 0;


        .boxTools {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 18px;
            margin-right: 14px;
        }

        h3 {

            padding: 14px 0 14px 24px;
        }
    }

    .listBox {
        margin: 0;

        .boxTitle {
            h3 {

                letter-spacing: 0.14em;
                text-transform: uppercase;
            }
        }
    }
}