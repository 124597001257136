@use "breakpoints.scss";

.miniProfile {
    box-shadow: 0 0 20px rgba(var(--mainFgRgb), 0.1);
    border: 1px solid var(--gray250);
    border-radius: var(--borderRadius);
    padding: 20px;
    background-color: var(--mainBg);
    min-width: 300px;
    max-width: 500px;
    display: flex;
    z-index: 999;

    .userInfo {

        display: block;
        padding-left: 16px;

        p {
            margin-top: -6px;
            // display: inline-flex;
            // align-items: center;
            // flex-direction: row;

        }
    }
}

/* Applies to large screens (+992px) */
@media screen and (breakpoints.$lgUp) {

    .miniProfile {
        .userInfo {
            p {
                flex-direction: column;
            }

        }
    }
}