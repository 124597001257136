.hitsWrapper {
    box-shadow: 0 10px 10px rgba(var(--mainFgRgb), .15);
    border-radius: 0 0 12px 12px;
    background: var(--mainBg);
    z-Index: 9999;
    width: 100%;
    text-align: start;
    animation: 0.3s ease-out 0s 1 fadeIn;

}

.hitWrapper {

    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 8px;
    border-bottom: 1px solid var(--brandLight5);
    text-decoration: none !important;
    z-index: 999;
    cursor: pointer;


    .iconAndName {
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
            margin-right: 8px;
        }
    }
}


.searchMeta {
    padding: 12px;
    border-bottom: 1px solid var(--gray100);

    span {
        cursor: pointer;
        color: var(--linkColor);
        font-weight: 700;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}