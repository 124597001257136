@use "breakpoints.scss";
@use "various.scss";

.headingAction {
    cursor: pointer;
    margin-left: various.$padding-base-2x;
    font-size: 50%;
}

.postComment {
    font-size: 0.8em;
    color: var(--metaLight);
    display: flex;
    align-items: center;
    line-height: 1.2;
    justify-content: flex-end;
    cursor: pointer;
}

// .comments {
//   margin-bottom: various.$padding-base-4x;
// }

.suggestFeature {
    display: none;

}

.metaBarItemWrapper {
    display: flex;
    gap: 4px;
    align-items: center;
    min-width: 100px;
    justify-content: space-between;
    font-size: 120%;
    cursor: pointer;

    &>svg {
        //border: 1px solid var(--brandLight5);
        //border-radius: 9px;
        padding: 6px;
        //background-color: var(--brandLight10);

        color: var(--popBrand);

        width: 32px;
        height: 32px;
    }

    .metaBarInfo {

        display: flex;
        flex-direction: column;
        flex-grow: 1;

        margin-left: 12px;

        &.noCursor {
            cursor: default;
        }

        .metaBarLarge {
            line-height: 1;
            font-size: 18px;
            font-weight: 500;

        }

        .metaBarSmall {
            font-size: 11px;
            line-height: 1;
            color: var(--meta);

        }


    }
}

.activityFilter {
    font-size: 0.80em;

    &>span {
        font-weight: 400;
        cursor: pointer;

        &.active {
            font-weight: 500;
        }
    }

}

.mainGrid {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .mainGridRightInner {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    >div:nth-child(1) {
        order: 2;
    }

    >div:nth-child(2) {
        order: 1;
    }
}

/* Applies to medium screens (+768px) */
@media screen and (breakpoints.$mdUp) {

    .metaBar {
        display: flex;


    }


    .metaBarItemWrapper {
        justify-content: unset;

        .ratingStars {
            display: inline;
        }
    }

    .mainGrid {
        .mainGridRight {

            min-width: 384px;

            .mainGridRightInner {
                display: grid;
                grid-template-columns: 1fr 1fr;

                gap: 6px;

                >div:nth-child(1) {
                    grid-column: span 2;
                }
            }
        }
    }
}



.experimentFlex {
    display: flex;
    flex-direction: row;
}

.experimentDesc {

    margin-right: 36px;
    max-width: 950px;

}


/* Applies to large screens (+992px) */
@media screen and (breakpoints.$lgUp) {

    .metaBar {
        display: flex;

    }

    .metaBarItemWrapper {
        justify-content: unset;

        .ratingStars {
            display: none;
        }
    }

    .suggestFeature {
        display: inline-flex;
        align-items: center;
        gap: 6px;
        margin-right: 14px;
        color: var(--metaLight);
        cursor: pointer;
    }



    .mainGrid {
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-template-rows: 1fr;
        gap: 24px;

        .mainGridRight {
            .mainGridRightInner {
                display: flex;
                flex-direction: column;
                gap: 24px;
            }
        }

        >div:nth-child(1) {
            order: 1;
        }

        >div:nth-child(2) {
            order: 2;
        }
    }

}

/* Applies to large screens (+1200px) */
@media screen and (breakpoints.$xlUp) {

    .metaBar {
        display: flex;

        .ratingStars {
            display: inline;
        }

    }

}

.stickyBar {
    display: none;
    background-color: var(--brandLight7);
    border: 1px solid var(--brandLight5);
    margin-top: 0;
    padding: 6px;
    position: sticky;
    top: 58px;
    z-index: 9;

    &.isSticky {
        display: block;
        border-radius: 0;
        box-shadow: 3px 3px 12px rgba(90, 115, 163, 0.2);
    }

    .stickyBarInner {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        min-height: 48px;
        padding: 0 12px;

    }

    .stickyBarLeft {
        display: flex;
        align-items: center;
        gap: 12px;

        h2 {
            font-size: 90%;
            margin: 0;
        }
    }

    .stickyBarRight {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: auto;
        gap: 12px;
    }

    .stickyLikeButton {
        &>div {
            &>span {
                &>span {
                    background-color: var(--ctaLightest);
                    border: 1px solid var(--ctaLight);
                }
            }
        }
    }
}

/* Applies to large screens (+1200px) */
@media screen and (breakpoints.$xlUp) {
    .stickyBar {
        top: 69px;
        border-radius: 12px;
        z-index: 9;

        &.isSticky {
            border-radius: 0 0 16px 16px;
            box-shadow: 3px 3px 12px rgba(90, 115, 163, 0.2);
        }
    }
}

@media screen and (breakpoints.$xxlUp) {
    .stickyBar {
        padding: 0 100px;
    }
}

.stickyBarInner {
    // ... existing styles ...
}

.stickyBarLeft {
    // ... existing styles ...

    @media (max-width: 768px) {
        .appName {
            display: none;
        }

        .stickyLikeButton {
            display: none;
        }
    }
}

.stickyBarRight {
    // ... existing styles ...

    @media (max-width: 768px) {
        .alternativesText {
            display: none;
        }
    }
}