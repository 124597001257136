@use 'breakpoints.scss';

.newsMeta {
    display: flex;

    flex-direction: column;
    gap: 16px;
}

.voterContainer {
    display: flex;
    min-width: 34px;
}

/* Applies to large screens (+992px) */
@media screen and (breakpoints.$lgUp) {
    .newsMeta {
        flex-direction: row;
        gap: 0;
        justify-content: space-between;
        align-items: center;
    }

}