@use "various.scss";

.likeButton {
  appearance: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: var(--topBrand);
  margin-top: 0.5em;
  padding: 0.6em 0 0.6em;
  letter-spacing: -0.01em;
  color: #fff;
  font-weight: 500;
  text-align: center;
  border-radius: var(--borderRadius);
  font-size: 0.9em;
  line-height: 1;
  cursor: pointer;
}

.isLiked {
  .heart {
    color: #eba5a3
  }
}

.likeButton:hover {
  background-color: var(--headingButton);

  .heart {
    color: #eba5a3;
  }
}



.heart {
  margin-right: various.$padding-base;
}