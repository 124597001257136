@use "various.scss";
@use "breakpoints.scss";

.newsItemWrapper {

    display: flex;
    align-items: center;

    img {
        display: none;
    }

    .newsItemLink {

        margin-top: -2px;
        width: 100%;

        span {
            margin-top: 0;

            a {
                color: var(--mainFg) !important;
            }
        }

        a {
            display: block;
        }
    }
}

@media screen and (breakpoints.$mdUp) {
    .newsItemWrapper {
        img {
            display: block;
        }
    }
}