@use "breakpoints.scss";

.newsMeta {
    font-size: 0.75em;
    text-transform: uppercase;
    color: var(--meta);
    display: flex;
    margin-top: 4px;

    a {
        color: var(--linkColorHeader);
        display: inline;
        font-weight: 500;
    }

    .luke {
        color: #b0bbcd;
    }

    .hot {
        color: #FAC000;
    }


    .hottest {
        color: #FC6400
    }
}

/* Applies to medium screens (+768px) */
@media screen and (breakpoints.$mdUp) {
    .newsMeta {

        .icon {
            display: none;
        }
    }
}

/* Applies to medium screens (+992px) */
@media screen and (breakpoints.$lgUp) {
    .newsMeta {

        .icon {
            display: block;
        }
    }
}