.commentSummary {
    background-color: var(--brandLight10);
    padding: 12px 28px 12px 8px;
    margin-bottom: 22px;
    margin-top: 12px;
    border: 1px solid var(--brandLight5);
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
    font-size: 0.9em;

    svg {
        margin-top: 6px;
        min-width: 34px;
    }

}