$smUp: "min-width: 576px"; // When the viewport is at least 576px wide.
$mdUp: "min-width: 768px"; // When larger than 768 i.e NOT mobile
$lgUp: "min-width: 992px"; // When larger than 992 i.e desktop
$xlUp: "min-width: 1200px"; // When larger than 1200 i.e wide desktop
$xxlUp: "min-width: 1400px"; // When larger than 1400 i.e wide desktop
$xlDown: "max-width: 1200px"; // When smaller than 1200 i.e tight desktop
$mdDown: "max-width: 768px"; // When smaller than 768 i.e mobile
$smDown: "max-width: 576px"; // When smaller than 768 i.e mobile
$microDown: "max-width: 250px"; // When extremely thin i.e some weird bot or something?

// breakpoints.scss
@mixin mobile {
    @media (max-width: 767px) {
        @content;
    }
}

@mixin tabletSmall {
    @media (max-width: 992px) {
        @content;
    }
}