        .saveAndCancel {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-top: 12px;
        }

        .saveAndCancel span,
        .saveAndCancel a {
            margin-right: 12px;
            cursor: pointer;
            font-size: 90%;
        }