@use 'breakpoints.scss';
@use 'various.scss';

.newsItem {
  position: relative;

  overflow: hidden;

  &.featured {

    padding: 24px 0 24px 0;

    h3 {
      margin-top: 2px;
      font-size: 130%;
    }
  }

  &.startpageFeed {

    h3 {
      margin-top: 2px;
      font-size: 100%;
    }
  }

  &.archive {

    h3 {
      font-size: 130%;
    }
  }

  &.hideImageOnMobile {
    @include breakpoints.mobile {
      .newsItemLink:first-child {
        display: none;
      }
    }
  }
}



.newsItem:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.newsItemLink {
  text-decoration: none;
  display: block;
}