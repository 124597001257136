@mixin hidden-opacity {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity var(--minorTransition) linear;
}

@mixin show-opacity {
    visibility: visible;
    opacity: 1;
}

$padding-base: 6px;
$padding-base-2x: $padding-base * 2;
$padding-base-3x: $padding-base * 3;
$padding-base-4x: $padding-base * 4;
$padding-base-5x: $padding-base * 5;
$padding-base-6x: $padding-base * 6;