.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;

  div {
    a {
      display: inline-block;
      padding: 0.5em;
      font-weight: 500;
      line-height: 1;
      color: var(--linkColor);
      cursor: pointer;
    }

    a.current {
      color: #aaa;
      cursor: text;
    }

    a.prev,
    a.next {
      font-size: 1.1em;
    }

    a.prev {
      margin-right: 1em;
    }

    a.next {
      margin-left: 1em;
    }
  }
}