@use "breakpoints.scss";

.bar {
    position: sticky;
    left: 0;
    right: 0;
    bottom: 12px;
    padding: 1em;
    z-index: 9;
    display: flex;
    background-color: var(--mainBg);


    &.sticky {
        width: 90%;
        margin: 0 auto;
        border: 1px solid var(--gray300);
        border-radius: 6px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 1px 2px rgba(0, 0, 0, 0.06);
        background-color: var(--gray100);
        //box-shadow: 0 -1px 25px rgba(var(--mainFgRgb), .1), 0 -3px 3px rgba(var(--mainFgRgb), .01), 0 -0.5px rgba(var(--mainFgRgb), .15);
    }

    .buttons {
        display: flex;
        align-items: center;
        font-size: 90%;
        gap: 8px;

    }

    .rightSide {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .leftSide {
        display: flex;
        align-items: center;

        a {
            text-decoration: none;

        }
    }
}



@media screen and (breakpoints.$xlUp) {
    .bar {



        .buttons {
            justify-content: space-between;
            font-size: 110%;
            gap: 16px;
            width: 100%;



        }

        // &.sticky {
        //     .buttons {
        //         width: 800px;
        //     }
        // }

        &.center {
            .buttons {
                justify-content: center;
            }
        }
    }
}