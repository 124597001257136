 @use "various.scss";


 .appNotice {
     margin: 0;
     background: var(--lightDanger);
     padding: 0.01em 0.75em 0.35em;
     border-radius: var(--borderRadius);
     word-break: break-word;
     color: var(--mainFg);
     flex: 1;

     span {
         font-weight: 500;
         margin-top: various.$padding-base;
         display: block;
         color: var(--danger);
     }

     &.warning {
         background: var(--negativeLight);
         border: 1px solid var(--lightDanger);

         span {
             color: #e08412;
         }
     }

     &.positive {
         background: var(--positiveGreenerLight);
         border: 1px solid var(--positiveGreener);

         span {
             color: var(--positiveGreener);
         }
     }

     a {
         color: var(--mainFg);
     }
 }