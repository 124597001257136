@use "various.scss";
@use "breakpoints.scss";

.inner {
    position: relative;
    margin-top: various.$padding-base-2x;
    margin-bottom: various.$padding-base-2x;



    .desc {

        margin: 0 0 16px 0;
        font-size: 0.95em;
        text-align: center;


    }

    .customMeta {
        color: var(--metaLight);
        display: flex;
        align-items: center;
        flex-direction: column;
        font-size: 0.85em;
        text-align: center;

        div {
            margin-top: 2px;
        }
    }
}