@use "breakpoints.scss";

.columnList {
    column-count: 1;
}

.columnListCategories {
    column-count: 1;
}

.columnList>li {
    break-inside: avoid;
    margin-bottom: 6px;
}

.columnList li ul {
    margin-top: 0.25em;
    line-height: 1.7;
}

.columnList li li {
    display: inline-block;
    margin-right: 0.75em;
}

.columnList li li span {
    font-size: 0.65em;
    opacity: 0.5;
}

.boxContainer {
    @media screen and (breakpoints.$lgUp) {
        display: flex;
        flex-direction: row;
        gap: 24px;
    }
}