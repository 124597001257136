.miniApp {
  border: 1px solid var(--gray200);
  border-radius: var(--borderRadius);
  padding: 20px;
  background-color: var(--mainBg);
  min-width: 330px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 99;
  color: var(--gray800);

  // p {
  //   font-size: 0.85em;
  // }

  .heart {
    font-size: 0.85em;
    color: var(--meta);
    font-weight: 500;
    margin-left: auto;
  }

  .desc {

    overflow-wrap: anywhere;

    a {
      color: var(--linkColor) !important;
    }
  }
}

.appName {
  font-size: 1.3em;
  font-weight: 500;
}

.appNameSmall {

  font-size: 1.1em;
  font-weight: 500;
}

.appNameLarge {

  font-size: 1.7em;
  font-weight: 500;
}

.iconName {
  display: flex;
  flex-direction: row;
  align-items: center;
}